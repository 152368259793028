/* eslint-disable no-param-reassign */
/**
 =========================================================
 * Material Kit 2 PRO React - v2.0.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-kit-pro-react
 * Copyright 2021 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';

// react-router mkcomponents
import { Link as RouterLink, useLocation } from 'react-router-dom';

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types';

import Icon from '@mui/material/Icon';
import { makeStyles } from '@mui/styles';
import NavbarItem from './NavbarItem';
import NavbarMobile from './NavbarMobile';
import breakpoints from 'assets/theme/base/breakpoints';
import {
  parseNotification,
  useReadNotification,
  useNotifications,
  useNotificationsUpdate,
} from 'services/notifications';
import NotificationItem from 'components/Navbar/NotificationItem';
import staticMenu, { isVisible } from '../../state/menu-and-routes';
import colors from 'assets/theme/base/colors';
import AuthContext from '../../state/AuthContext';
import Logo from 'assets/images/logo.png';
import Dropdown from './Dropdown';
import { useSignOut } from '../../services/auth';
import { useTrustedActionsRef } from '../../services/config';
import { flattenPages } from '../../util/query';
import { track } from '../../services/analytics';
import SearchBar from '../Search/SearchBar';
import classnames from 'classnames';

const useStyles = makeStyles({
  navMenuItem: {
    borderRadius: 8,
    cursor: 'pointer',
    transition: 'all 300ms linear',
    '&:hover': {
      backgroundColor: colors.grey[200],
      color: colors.dark.main,
      '& *': {
        color: colors.dark.main,
      },
    },
  },
  markAll: {
    backgroundColor: colors.brand.background,
    color: colors.brand.default,
    width: '100%',
    height: '47px',
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 400,
    justifyContent: 'center',
    textAlign: 'center',
    display: 'flex',
    alignSelf: 'center',
    alignItems: 'center',
    padding: '10px',
    span: {
      cursor: 'pointer',
    },
  },
});

function Navbar({ selfChannel, brand, transparent, light, relative }) {
  const [menu, setMenu] = useState(staticMenu);
  const [dropdown, setDropdown] = useState(null);
  const [dropdownName, setDropdownName] = useState('');
  const [isMobileNavbarOpen, setMobileNavbarOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(false);
  const { me, isGuest, completedSignup, isAdmin, isAuthenticated, isDelegated } =
    useContext(AuthContext);
  const signOut = useSignOut();
  const navbarRef = useRef(null);
  const location = useLocation();
  const notificationsQuery = useNotifications({ enabled: isAuthenticated });
  const notifications = useMemo(
    () => flattenPages(notificationsQuery.data?.pages),
    [notificationsQuery.data?.pages],
  );
  const readNotification = useReadNotification();
  const trustedActions = useTrustedActionsRef();
  const { addNotification, markNotificationAsRead, deleteNotification } = useNotificationsUpdate();
  const [searching, setSearching] = useState(false);
  useEffect(() => setSearching(location.pathname.startsWith('/search')), [location, setSearching]);

  const classes = useStyles();

  useEffect(() => {
    function onClickAnywhere(e) {
      if (dropdown?.ref && !dropdown.ref.contains(e.target)) collapseMenu();
    }

    document.removeEventListener('click', onClickAnywhere, false);
    document.addEventListener('click', onClickAnywhere, false);
    return () => document.removeEventListener('click', onClickAnywhere, false);
  }, [dropdown]);

  useEffect(() => {
    const flags = {
      isGuest,
      isAuthenticated,
      isDelegated,
      completedSignup,
      isAdmin,
      trustLevel: me?.trustLevel,
    };
    setMenu(oldMenu =>
      oldMenu.map(item => ({
        ...item,
        hidden: !isVisible({ item, ...flags, trustedActions: trustedActions.current }),
        collapse: item.collapse?.map(item => {
          return {
            ...item,
            highlighted: item.id === 'messages' && me?.dms,
            hidden: !isVisible({ item, ...flags, trustedActions: trustedActions.current }),
          };
        }),
      })),
    );
  }, [
    isGuest,
    isAuthenticated,
    isDelegated,
    completedSignup,
    isAdmin,
    trustedActions,
    trustedActions.current?.length,
    me,
  ]);

  useEffect(() => {
    if (!selfChannel) {
      return;
    }

    selfChannel.bind('new-notification', ({ notification }) => {
      addNotification(notification);
    });

    selfChannel.bind('read-notification', ({ id }) => {
      markNotificationAsRead(id);
    });

    selfChannel.bind('delete-notification', ({ id }) => {
      deleteNotification(id);
    });

    return () => {
      selfChannel.unbind('new-notifications');
      selfChannel.unbind('read-notifications');
      selfChannel.unbind('delete-notifications');
    };
  }, [selfChannel, addNotification, deleteNotification, markNotificationAsRead]);

  // insert notifications into menu
  useEffect(
    () => {
      if (notificationsQuery.isSuccess) {
        const notificationsMenu = notifications
          ?.map(notification => {
            const parsedNotification = parseNotification(notification);

            if (!parsedNotification) {
              console.error('malformed notification', notification);
              return null;
            }

            return {
              key: notification.id,
              name: <NotificationItem notification={parsedNotification} />,
              backgroundColor: parsedNotification.read ? undefined : '#f5fbff',
              route: parsedNotification.route,
              onClickMenuItem() {
                if (!notification.read) {
                  readNotification.mutate(notification.id);
                }
              },
            };
          })
          .filter(notification => notification);
        if (notifications.length === 0)
          notificationsMenu?.unshift({
            key: 'empty',
            className: classes.markAll,
            name: 'No notifications',
          });
        else if (notifications.some(n => !n.read))
          notificationsMenu?.unshift({
            key: 'markall',
            className: classes.markAll,
            name: 'Mark All As Read',
            onClickMenuItem() {
              track('mark-all-notifications-read');
              readNotification.mutate();
            },
          });
        setMenu(oldMenu =>
          oldMenu.map(mi =>
            mi.name === 'Notifications'
              ? {
                  ...mi,
                  collapse: notificationsMenu,
                  onReachBottom: () => notificationsQuery.fetchNextPage({ cancelRefetch: false }),
                }
              : mi,
          ),
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [notifications],
  );

  useEffect(
    () => {
      // also clear any dropdowns
      collapseMenu();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [location.pathname],
  );

  useEffect(() => {
    setMenu(oldMenu =>
      oldMenu.map(menu => ({
        ...menu,
        ...(me && menu.id === 'messages'
          ? { name: me.dms ? `Messages (${me.dms > 10 ? '10+' : me.dms})` : 'Messages' }
          : null),
      })),
    );
  }, [me]);

  useEffect(() => {
    // A function that sets the display state for the NavbarMobile.
    function displayMobileNavbar() {
      setIsMobileView(window.innerWidth < breakpoints.values.md);
      setMobileNavbarOpen(false);
    }

    /**
     The event listener that's calling the displayMobileNavbar function when
     resizing the window.
     */
    window.addEventListener('resize', displayMobileNavbar);

    // Call the displayMobileNavbar function to set the state with the initial value.
    displayMobileNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', displayMobileNavbar);
  }, []);

  async function onClickMenuItem(name) {
    if (typeof name === 'string') await track('click-menu-item', { name });
    setMobileNavbarOpen(false);
    if (name === 'Sign Out') signOut();
    if (name === 'Search') setSearching(true);
  }

  function expand(item, currentTarget) {
    if (item.collapse) {
      setDropdown({ item, ref: currentTarget });
      setDropdownName(item.name);
    }
  }

  function collapseMenu() {
    setDropdown(null);
    setDropdownName(null);
  }

  function toggleMobileMenu() {
    track('toggle-mobile-menu', { opening: !isMobileNavbarOpen });
    setMobileNavbarOpen(!isMobileNavbarOpen);
  }

  const dropdownItems = menu.reduce((foundItems, { name, collapse, hidden }) => {
    if (foundItems) return foundItems;
    else if (hidden) return null;
    else if (collapse && name === dropdownName) return collapse;
    else return null;
  }, null);

  const renderedNavbarItems = menu
    ?.filter(item => !isMobileView || item.icon)
    .map(
      item =>
        !item.hidden && (
          <NavbarItem
            key={item.name}
            name={item.name}
            icon={item.icon}
            href={item.href}
            route={item.route}
            collapse={Boolean(item.collapse)}
            onClick={({ currentTarget }) => {
              expand(item, currentTarget);
              onClickMenuItem(item.name);
            }}
            onMouseEnter={({ currentTarget }) => expand(item, currentTarget)}
            light={light}
            notifications={notifications}
          />
        ),
    );

  return (
    <Box ref={navbarRef}>
      <Box
        py={1}
        px={2}
        width={relative ? '100%' : 'calc(100% - 48px)'}
        borderRadius='xl'
        shadow={transparent ? 'none' : 'md'}
        color={light ? 'white' : 'dark'}
        position={relative ? 'relative' : 'absolute'}
        left={0}
        zIndex={3}
      >
        <div className={classnames('flex flex-row h-9 md:h-12 items-center justify-between')}>
          <Box
            component={location.pathname === '/' ? undefined : RouterLink}
            to='/'
            onClick={() => track('click-home')}
            lineHeight={1}
            py={transparent ? 1.5 : 0.75}
            style={{ textDecoration: 'none', flexShrink: '0' }}
            pl={relative || transparent ? 0 : { md: 0, lg: 1 }}
          >
            <div className='flex items-center'>
              <img src={Logo} className='w-10 h-5' alt='Logo' />
              {(!isMobileView || !searching) && <p className='text-lg ml-1 mb-1'>{brand}</p>}
            </div>
          </Box>
          {searching ? (
            <div className='mx-4 md:mx-8 flex-grow'>
              <SearchBar onClose={() => setSearching(false)} />
            </div>
          ) : (
            <div className='flex ml-auto items-center'>
              {renderedNavbarItems}
              <div
                className={classnames(
                  'relative flex items-center text-slate-500 ml-3',
                  isMobileView ? 'block' : 'hidden',
                )}
                onClick={toggleMobileMenu}
              >
                {!!me?.dms && !isMobileNavbarOpen && (
                  <span className='absolute w-3 h-3 bg-red-600 border border-white rounded-full top-1 right-0 ' />
                )}
                <Icon fontSize='large'>{isMobileNavbarOpen ? 'close' : 'menu'}</Icon>
              </div>
            </div>
          )}
        </div>
        <Box
          display={isMobileView ? 'block' : 'none'}
          bgColor={transparent ? 'white' : 'transparent'}
          shadow={transparent ? 'lg' : 'none'}
          borderRadius='xl'
          px={transparent ? 2 : 0}
        >
          {isMobileView && (
            <NavbarMobile
              notifications={notifications}
              routes={menu}
              open={isMobileNavbarOpen}
              onClickMenuItem={onClickMenuItem}
            />
          )}
        </Box>
      </Box>

      <Dropdown
        items={dropdownItems}
        parentRef={dropdown?.ref}
        onReachBottom={dropdown?.item?.onReachBottom}
        onClickMenuItem={onClickMenuItem}
      />
    </Box>
  );
}

// Setting default values for the props of DefaultNavbar
Navbar.defaultProps = {
  brand: 'Evincer',
  transparent: false,
  light: false,
  action: false,
  sticky: false,
  relative: false,
  center: false,
};

// Typechecking props for the DefaultNavbar
Navbar.propTypes = {
  brand: PropTypes.string,
  transparent: PropTypes.bool,
  light: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      type: PropTypes.oneOf(['external', 'internal']).isRequired,
      route: PropTypes.string.isRequired,
      color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'dark',
        'light',
        'default',
        'white',
      ]),
      label: PropTypes.string.isRequired,
    }),
  ]),
  sticky: PropTypes.bool,
  relative: PropTypes.bool,
  center: PropTypes.bool,
};

export default Navbar;
